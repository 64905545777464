/* ==========================================================================
   Site wide styles
   ========================================================================== */

/* 
   Selection
   ========================================================================== */

::-moz-selection { 
	background-color: lighten($basecolor, 65%); 
	color: $basecolor; 
	text-shadow: none; 
}  	
::selection { 
	background-color: lighten($basecolor, 65%); 
	color: $basecolor; 
	text-shadow: none; 
}

img.header-image {
	border-top: 1px;
	border-bottom: 1px;
	border-style: solid;
	border-color: #000;
}

span.share-bluesky {
    display: block;
    height: 1.6em;
    width: 1.6em;
    text-align: center;
    border-radius: 0.2em;
    background: #1185fe;
}

span.share-bluesky > svg {
    fill: #fff;
    width: 1em;
    height: 1em;
    margin: 0.3em;
}


/* 
   Global classes
   ========================================================================== */

/* Capitalize */
.all-caps {
	text-transform: uppercase;
}



/* Float left */
.pull-left {
	float: left;
}

/* Float right */
.pull-right {
	float: right;
}
.image-pull-right {
	float: right;
	margin-top: 0;
}

/* Clearfix */
.clearfix {
	*zoom: 1;
	&:before,
	&:after {
		display: table;
	    content: "";
	}
	&:after {
		clear: both;
	}
}

/* Remove bullets and indentation from list */
.unstyled-list {
	list-style: none;
	margin-left: 0;
	padding-left: 0;
	li {
		list-style-type: none;
	}
}

/* 
   Global transition
   ========================================================================== */

b, i, strong, em, blockquote, p, q, span, figure, img, h1, h2, header, input, a {
	@include transition(all .2s ease);
}