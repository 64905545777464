/* ==========================================================================
   Sass variables
   ========================================================================== */

/* 
   Typography
   ========================================================================== */

$doc-font-size: 16;
$doc-line-height: 26;
$paragraph-indent: true !default;
$base-font: 'PT Serif', serif;
$heading-font: 'PT Sans Narrow', sans-serif;
$code-font: Monaco, "Courier New", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", monospace;
$alt-font: $base-font;

/* 
   Colors
   ========================================================================== */

$bodycolor      : #fff;
$body-color     : $bodycolor;
$textcolor      : #333332;
$text-color     : $textcolor;
$border-color   : $text-color;
$basecolor 		  : #343434;	  
$compcolor 		  : spin($basecolor, 180); 
$bordercolor    : $basecolor;
$white          : #fff;
$black          : #111;
$accentcolor    : $black;

/* buttons */
$primary			: $black;
$success			: #5cb85c;
$warning			: #dd8338;
$danger				: #C64537;
$info				  : #308cbc;

/* brands */
$facebook-color     : #3b5998;
$flickr-color       : #ff0084;
$foursquare-color   : #0cbadf;
$google-plus-color  : #dd4b39;
$instagram-color    : #4e433c;
$linkedin-color     : #4875b4;
$pinterest-color    : #cb2027;
$rss-color          : #fa9b39;
$tumblr-color       : #2c4762;
$twitter-color      : #55acee;
$vimeo-color        : #1ab7ea;
$youtube-color      : #ff3333;

/* links */
$linkcolor 		    : #343434;
$link-color       : $linkcolor;
$linkcolorhover 	: darken($linkcolor, 10);
$linkcolorvisited : lighten($linkcolorhover, 20);
$linkcolorfocus 	: darken($linkcolorvisited, 10);

/* 
   Breakpoints
   ========================================================================== */
   
$micro            : "only screen and (min-width: 30em)";
$small            : "only screen and (min-width: 37.5em)";
$medium           : "only screen and (min-width: 48em)";
$large            : "only screen and (min-width: 62em)";
$x-large          : "only screen and (min-width: 86.375em)";